<template>
  <div class="step3-div">
    <div>
      <p class="fu-v2-title text-size">{{ $t('ebClaim.event') }}</p>
      <div v-if="check && !eventValue" class="van-field__error-message error-local">{{ $t('ebClaim.eventIsRequired') }}</div>
      <div class="event-wapper">
        <div
          v-for="(item, index) in eventOptions"
          :key="index"
          class="event-item"
          :class="eventValue === item.code ? 'event-item-active' : ''"
          @click="handleEventClick(item.code, getText(item))"
        >
          {{ getText(item) }}
        </div>
      </div>
    </div>

    <FuFormMin
      v-if="check && !loading"
      ref="FuForm"
      v-model="formData"
      :extend-datas="policyInfo"
      class="p10"
      :schema="formColumns"
      :lang="lang"
    />

    <div class="btn-group">
      <div class="btn-group-wapper">
        <Button
          class="fu-v2-btn block"
          round
          block
          type="danger"
          @click="$parent.step = 1"
        >{{ $t('ebClaim.Back') }}</Button>
        <span />
        <Button
          class="fu-v2-btn danger block"
          round
          block
          type="danger"
          @click="getClaimPdf"
        >{{ $t('ebClaim.Next') }}</Button>
      </div>
    </div>

    <div v-if="showIframe" class="iframe-div">
      <iframe
        class="fu-v2-iframe"
        :src="pdfUrl"
      />
      <div class="btn-group">
        <div class="btn-group-wapper">
          <Button
            class="fu-v2-btn block"
            round
            block
            type="danger"
            @click="showIframe = false"
          >{{ $t('ebClaim.Back') }}</Button>
          <span />
          <Button
            class="fu-v2-btn danger block"
            round
            block
            type="danger"
            @click="gotoVerifyPage"
          >{{ $t('ebClaim.Next') }}</Button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FuFormMin from '@/components/FuFormMin'
import { Button } from 'vant'
import { getClaimPortalList, getClaimPortalForm, getClaimPdf, queryEvent, sendOtp } from '@/views/claim/api/index.js'
import { toastLoading } from '@/utils'
import { Session } from '@/utils/storage'
import { formatDateId } from '@/filters'
export default {
  components: { FuFormMin, Button },
  inject: ['getStep1Data', 'getPolicyItem', 'getStep2Data', 'getReportInfo'],
  props: {},
  data() {
    return {
      check: false,
      eventValue: '',
      eventOptions: [
        // { label: this.$t('Inpatient'), value: 0 },
        // { label: this.$t('Passport'), value: 0 },
        // { label: this.$t('Dental'), value: 0 },
        // { label: this.$t('Surgary'), value: 0 },
        // { label: this.$t('Maternity'), value: 0 },
        // { label: this.$t('Special Case'), value: 0 }
      ],
      active: '1',
      loading: true,
      formData: {},
      formColumns: {},
      showIframe: false,
      pdfUrl: '',
      fileKey: ''
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang
    },
    getText() {
      return (item) => {
        return this.$store.state.base.lang == 'en' ? item.text : item.localText
      }
    },
    policyInfo() {
      const { policyNo, rawPlanCode, slipUid } = this.getPolicyItem()
      return {
        policyNo, rawPlanCode, slipUid
      }
    }
  },
  mounted() {
    this.getClaimPortalList()
    this.queryEvent()
  },
  methods: {
    handleEventClick(val, name) {
      this.check = false
      this.eventValue = val
      const temp = JSON.parse(JSON.stringify(this.formData || '{}'))
      if (temp.eventDetail) {
        temp.eventDetail.coverageKey = val
        temp.eventDetail.coverageName = name
      } else {
        temp.eventDetail = { coverageKey: val, coverageName: name }
      }
      temp.eventDetail
      setTimeout(() => {
        this.check = true
        this.formData = temp
      }, 0)
    },
    async queryEvent() {
      const { rawPlanCode, slipUid: slipId } = this.policyInfo
      const res = await queryEvent({ rawProductPlanCode: rawPlanCode, slipId })
      this.eventOptions = res && res.length ? res : []
    },
    async getClaimPortalList() {
      this.loading = toastLoading()
      const params = {
        'processCode': 'PF_00022',
        'productCategory': 'health',
        'status': 1,
        'pageSize': 1000
      }
      const list = await getClaimPortalList(params)
      const res = await getClaimPortalForm({ processCode: list.data[0].processCode })
      this.loading.clear()
      this.loading = null
      this.formColumns = JSON.parse(res.displaySchema)
    },
    formValid() {
      // console.log(this.formData)
      this.check = true
      if (!this.eventValue) {
        return Promise.resolve(false)
      }
      return new Promise((resolve) => {
        this.$refs.FuForm.validate().then(res => {
          resolve(true)
        }).catch(() => {
          resolve(false) // TODO: 此处需要修改
        })
      })
    },
    async getClaimPdf() {
      // this.showIframe = true
      // if (this) return // TODO: 删除
      const valid = await this.formValid()
      if (!valid) return
      const { dol } = this.getStep1Data() || {}
      const { identityNo: claimantIdCardNumber, insuredName, startTime, endTime, insuredBirth, identityNo: insuredIdCardNumber, insuranceCardNumber } = this.getPolicyItem() || {}
      const { reporterInfo, reimburesementBankInfo } = this.getStep2Data() || {}
      const { relation, reporter: claimantName, reporterMobile: phone, reporterMobileIdd } = reporterInfo || {}
      const { bankAccountName, bankAccountNumber, bankCodeLabel: bankName } = reimburesementBankInfo || {}
      const {
        eventDetail
        // eventInfo
      } = this.formData
      const { hospital, diagnosis, accidentTime, claimantAmount } = eventDetail
      const dateObj = new Date(accidentTime)
      const claimDateDay = dateObj.getDate() < 10 ? '0' + dateObj.getDate() : dateObj.getDate()
      const claimDateMonth = dateObj.getMonth() + 1 < 10 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1
      const claimDateYY = (dateObj.getFullYear()) % 100 // 去掉年份前面两位
      // const { eventTypeLabel, eventType: event } = eventInfo
      const relationship = ['P', 'S', 'C', 'E'][relation]
      const { reportUid } = this.getReportInfo()
      const params = {
        dol: formatDateId(dol, 'DDMMYYYY'),
        claimantIdCardNumber, insuredName, startTime: formatDateId(startTime + '000', 'DDMMYYYY'), endTime: formatDateId(endTime + '000', 'DDMMYYYY'),
        insuredBirth: formatDateId(insuredBirth, 'DDMMYYYY'), insuredIdCardNumber, insuranceCardNumber,
        relationship, claimantName, phone, reporterMobileIdd, bankAccountName, bankAccountNumber, bankName,
        // event, eventTypeLabel,
        event: this.eventValue,
        hospital, diagnosis, claimDateDay, claimDateMonth, claimDateYY, accidentTime, claimantAmount,
        formDataJson: JSON.stringify(this.formData),
        reportUid,
        language: this.lang == 'en' ? 'en_US' : 'vi'
      }
      this.loading = toastLoading()
      const res = await getClaimPdf(params)
      this.pdfUrl = res.fileUrl
      if (this.pdfUrl) {
        this.fileKey = res.fileKey
        this.showIframe = true
      }
      this.loading.clear()
      this.loading = null
    },
    async gotoVerifyPage() {
      const { identityNo: claimantIdCard, policyNo, slipUid } = this.getPolicyItem()
      const { insuredName } = this.getStep1Data() || {}
      const { reporterInfo } = this.getStep2Data()
      const { reporterMobile: phone, reporterMobileIdd } = reporterInfo
      console.log(reporterMobileIdd) // 前缀
      Session.set('eb-claim-application-form', this.formData)
      const { formNo, reportUid } = this.getReportInfo()

      const params = {
        bizType: 1, claimantIdCard, claimantPhone: `${reporterMobileIdd}${phone}`, policyNo, slipUid, employeeName: insuredName, countryCode: 'VN'
      }
      this.loading = toastLoading()
      const res = await sendOtp(params)
      this.loading && this.loading.clear()
      this.loading = null
      if (res.sent) {
        this.$router.push({ name: 'VerifyCode', query: {
          bizType: 1,
          claimantIdCard,
          claimantPhone: `${reporterMobileIdd}${phone}`,
          policyNo,
          slipUid,
          formNo, reportUid,
          fileKey: this.fileKey
        }})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.text-size {
  font-size: 16px!important;
}
.error-local {
  margin-left: 15px;
}
.event-wapper {
  padding: 10px;
  border-bottom: 8px solid #f5f5f5;
  justify-content: space-between;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .event-item {
    text-align: center;
    width: 48%;
    border: 1px solid #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .event-item-active {
    border: 1px solid #E28800;
    background-color: #fff3e1;
    color: #E28800;
  }
}
.step3-div {
  // padding-bottom: 50px;
  overflow-y: scroll;
}
.btn-group {
  padding: 15px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  .btn-group-wapper {
    display: flex;
    gap: 10px;
  }
}
.iframe-div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  background-color: #fff;
  z-index: 99;
  overflow: scroll;
  .fu-v2-iframe {
    width: 100%;
    height: 90%;
    // padding-bottom: 50px;
  }
}
</style>
